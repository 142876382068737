// @flow

import React from 'react';
import { observer } from 'mobx-react';

import styles from './style.module.scss';
import Markdown from "../../Markdown";
import Anchor from '../../Anchor';
import Divider from "../../Divider";

type MapBlurbBlockProps = {
  data: {
    dividerBelow: boolean,
    fragmentPath?: string,
    iFrameUrl: string,
    blurb: any,
  },
}

@observer
class MapBlurbBlock extends React.Component<MapBlurbBlockProps, any> {

  render() {

    return (
      <div className={styles.wrapper}>
        {this.props.data.fragmentPath &&  <Anchor id={this.props.data.fragmentPath}/>}
        <div className={styles.container}>
          <div className={styles.blurb}>
            <Markdown markdown={this.props.data.blurb}/>
          </div>
          <div className={styles.frame}>
            <iframe src={this.props.data.iFrameUrl}/>
          </div>
        </div>
        {this.props.data.dividerBelow && <div className={styles.divider}>
          <Divider color={'#ff6d64'}/>
        </div>}
      </div>
    )
  }
}

export default MapBlurbBlock;
