// @flow

import React from 'react';
import { observer, inject } from 'mobx-react';
import { graphql, Link } from 'gatsby';
import { Provider } from 'mobx-react';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import * as classNames from "classnames";

import { STORE_JSON, STORE_UI, STORE_OVERLAY } from '../constants/stores';
import JsonStore from '../stores/json-store';
import OverlayStore from '../stores/overlay-store';
import UiStore from '../stores/ui-store';
import styles from './page.module.scss';
import CTABlock from "../components/blocks/CTABlock";
import PhotoBlurbBlock from "../components/blocks/PhotoBlurbBlock";
import TextBlock from "../components/blocks/TextBlock";
import NavigationBlock from "../components/blocks/NavigationBlock";
import QuestionAnswerBlock from "../components/blocks/QuestionAnswerBlock";
import MapBlurbBlock from "../components/blocks/MapBlurbBlock";
import LogoBlock from "../components/blocks/LogoBlock";
import PurchaseItemBlock from "../components/blocks/PurchaseItemBlock";
import Metadata from '../components/Metadata';
import IFrameBlock from "../components/blocks/IFrameBlock";
import GalleryBlock from "../components/blocks/GalleryBlock";
import LineupBlock from "../components/blocks/LineupBlock";
import Anchor from "../components/Anchor";
// import { LogoColeman } from "../components/Icons";
import { wrapSymbols } from "../utils/wrap-symbol";


type IndexProps = {
  overlay: OverlayStore,
  json: JsonStore,
  ui: UiStore,
  data: any,
  location: any,
}

@inject(STORE_JSON, STORE_UI, STORE_OVERLAY)
@observer
class IndexPage extends React.Component<IndexProps, any> {

  stores: any;

  constructor(props: IndexProps, context: any) {
    super(props, context);
    this.props[STORE_JSON].setJsonFromGraphQL(this.props.data, this.props.data.page.url);
  }

  componentDidMount() {
    if (process.env.GATSBY_PREVIEW === 'true' && window) {
      // if (window.ga) {
      //   window.ga('set', 'page', this.props.data.page.url);
      //   window.ga('send', 'pageview');
      // }
      this.props[STORE_JSON].getData('page', {
        contentType: 'page',
        fieldEquality: {
          url: this.props.data.page.url
        }
      }, false, this.props.data.page.url);
    }
    if (window && this.props.data.page.url.toLocaleLowerCase() === 'lineup' ) {
      const scriptPrefix = 'https://events.aegamp.com/stagecoach2022/js/';
      var scripts = ['chunk-vendors.js', 'app.js'];

      scripts.forEach(script => {
        const lastScript = document.getElementsByTagName('script')[0];
        let s = document.createElement('script');

        s.type = 'text/javascript';
        s.async = true;
        s.src = `${scriptPrefix}${script}`;
        lastScript.parentNode.insertBefore(s, lastScript);
      });
    }
  }

  render() {

    const LinkButton = props => {
      if (props.url) {
        return (
          <Link to={`/${props.url}`}>
            {props.children}
          </Link>
        )
      } else return (
        <a
          href={props.external}
          target={'_blank'}
          rel="noopener noreferrer"
        >
          {props.children}
        </a>
      )
    };

    const jsonData: any = this.props[STORE_JSON].store.get(this.props.data.page.url);
    const data = jsonData ? jsonData : this.props.data;
    let pageTitle: string;
    let titleData: any;
    let headerEl: any;

    if (data.page.navigationTiles && data.page.navigationTiles.tiles) {
      titleData = filter(data.page.navigationTiles.tiles, { url: this.props.data.page.url })[0];
      if (titleData) pageTitle = titleData.display;
    }

    if (this.props.data.page.url === 'the-resort/overview') {
      const content = (pageTitle) ? pageTitle.toLocaleLowerCase().replace('coleman®', '') : data.page.pageTitle.toLocaleLowerCase().replace('coleman®', '');

      headerEl = (
        <h2
          className={classNames({
            [styles.pageHeader]: true,
            [styles.pageHeaderLogo]: true
          })}
        >
          {content}
        </h2>
      );
    } else {
      const content = (pageTitle) ? wrapSymbols(pageTitle) : (data.page.pageTitle) ? wrapSymbols(data.page.pageTitle) : null;

      headerEl = content && (
        <h2
          className={styles.pageHeader}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      );
    }

    return (
      <Provider {...this.stores}>
        <>
          <Metadata data={this.props.data.page.metadata || {}}
            location={this.props.location} />
          <div className={classNames({
            [styles.container]: true,
            [styles.noBlocks]: !data.page.blocks,
            [styles.hasBlocks]: data.page.blocks && data.page.background !== 'beige',
          })}>
            {data.page.linkBack && <div className={styles.linkBack}>
              <div className={styles.wrapper}>
                <LinkButton
                  url={data.page.linkBack.url || ''}>
                  <svg version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 48 48">
                    <path
                      fill="black"
                      d="M31.174,19.83c-0.419-0.451-1.096-0.451-1.514,0l-5.667,6.111l-5.666-6.111 c-0.418-0.451-1.096-0.451-1.514,0s-0.418,1.182,0,1.633l6.423,6.927c0.209,0.226,0.483,0.339,0.757,0.339 c0.274,0,0.548-0.113,0.757-0.339l6.424-6.927C31.592,21.012,31.592,20.281,31.174,19.83z" />
                  </svg>
                  {data.page.linkBack.display}
                </LinkButton>
              </div>
            </div>}
            <div className={styles.content}>
              {headerEl}
              {this.props.data.page.url.toLocaleLowerCase() === 'lineup' && (
                <div id="ww" className={styles.lineupWidget} data-path="11/ypatfxwwp4s9" />
              )}
              {data.page.blocks && <div className={styles.blocks}>
                {data.page.blocks.map((item, index) => {
                  return (
                    <React.Fragment
                      key={`${item.contentType}${index}${this.props.data.page.url}`}>
                      <Anchor id={`content`} />
                      {includes(['ContentfulPhotoBlurbBlock', 'photoBlurbBlock'], item.contentType) &&
                        <PhotoBlurbBlock data={item}
                          ui={this.props.ui} />}
                      {includes(['ContentfulCtaBlock', 'ctaBlock'], item.contentType) &&
                        <CTABlock data={item} />}
                      {includes(['ContentfulTextBlock', 'textBlock'], item.contentType) &&
                        <TextBlock data={item} />}
                      {includes(['ContentfulNavigationBlock', 'navigationBlock'], item.contentType) &&
                        <NavigationBlock data={item} />}
                      {includes(['ContentfulQaBlock', 'qaBlock'], item.contentType) &&
                        <QuestionAnswerBlock data={item} />}
                      {includes(['ContentfulMapBlurbBlock', 'mapBlurbBlock'], item.contentType) &&
                        <MapBlurbBlock data={item} />}
                      {includes(['ContentfulLogoBlock', 'logoBlock'], item.contentType) &&
                        <LogoBlock data={item} />}
                      {includes(['ContentfulPurchaseItemBlock', 'purchaseItemBlock'], item.contentType) &&
                        <PurchaseItemBlock data={item} />}
                      {includes(['ContentfulLineupBlock', 'lineupBlock'], item.contentType) &&
                        <LineupBlock data={item} />}
                      {includes(['ContentfulIFrameBlock', 'iFrameBlock'], item.contentType) &&
                        <IFrameBlock data={item} />}
                      {includes(['ContentfulGalleryBlock', 'galleryBlock'], item.contentType) &&
                        <GalleryBlock data={item}
                          ui={this.props.ui}
                          overlay={this.props.overlay} />}
                    </React.Fragment>
                  )
                })}
              </div>}
            </div>
          </div>
          {data.page.blocks && <div className={styles.spacer}>

          </div>}
        </>
      </Provider>
    )
  }
}

export const query = graphql`
query($url: String!) {
  page: contentfulPage(url:{eq:$url}){
      blocks {
          contentType: __typename
          ...on ContentfulGalleryBlock {
            header {
              markdown: header
            }
            fragmentPath
            dividerBelow
            images {
              large: src {
                file {
                  url
                  details {
                    image {
                      width
                      height
                    }
                  }
                }
              }
              small: srcSmall {
                file {
                  url
                  details {
                    image {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          ...on ContentfulIFrameBlock {
              height
              dividerBelow
              fragmentPath
              url
              ratioPreset
              heightWidthRatio
              header {
              markdown: header
            }
          }
          ...on ContentfulMapBlurbBlock {
            dividerBelow
            blurb {
              markdown:blurb
            }
            iFrameUrl
            fragmentPath
          }
          ...on ContentfulPurchaseItemBlock {
            header {
              markdown: header
            }
            disclaimer {
              markdown: disclaimer
            }
            purchaseItems {
              price
              priceColor
              totUrl
              priceUrl
              display
              image {
                file {
                  url
                }
              }
              button {
                url
                externalUrl
                display
              }
              blurb {
                markdown: blurb
              }
            }
          }
          ...on ContentfulLogoBlock {
            type
            header {
              markdown: header
            }
            fragmentPath
            dividerBelow
            logos {
              display
              url
              externalUrl
              image {
                file {
                  url
                }
              }
            }
          }
          ...on ContentfulCtaBlock {
            dividerBelow
            fragmentPath
            title
            buttons {
              type
              display
              url
              externalUrl
            }
          }
          ...on ContentfulQaBlock {
            title
            header {
              markdown: header
            }
            questions {
              fragmentPath
              question
              answer {
                markdown: answer
              }
            }
          }
          ...on ContentfulNavigationBlock {
            fragmentLinks
            name
            links {
              display
              url
              externalUrl
            }
          }
          ...on ContentfulTextBlock {
            secondColumnVerticalAlign
            disableAccodrion
            noVerticalDivider
            dividerBelow
            fragmentPath
            header {
              markdown: header
            }
            content {
              markdown: content
            }
            secondColumn {
              markdown: secondColumn
            }
            thirdColumn {
              markdown: thirdColumn
            }
            listBulletStyle
            fancyBorder
          }
          ...on ContentfulPhotoBlurbBlock {
            disableAccordian
            dividerBelowTextBlock
            dividerBelow
            schedule {
              friday {
                display
                time
              }
              saturday {
                display
                time
              }
              sunday {
                display
                time
              }
            }
            header {
                markdown: header
            }
            framedImage {
              type
              src {
                file {
                  url
                }
              }
              srcSmall {
                file {
                  url
                }
              }
            }
            secondFramedImage {
              type
              src {
                file {
                  url
                }
              }
              srcSmall {
                file {
                  url
                }
              }
            }
            fragmentPath
            blurb {
              markdown: blurb
            }
          }
      }
      pageTitle
      navigationTiles{
          tiles {
            url
            display
          }
        }
      url
      background
      linkBack {
        display
        url
      }
      metadata {
      title
      description
      image {
        file {
          url
        }
      }
      ogTitle
      ogDescription
      twitterCard
    }
  }
}`;

export default IndexPage;
