// @flow

import React from 'react';
import { observable, action, reaction } from 'mobx';
import { observer } from 'mobx-react';
import * as classNames from 'classnames';
import ReactResizeDetector from 'react-resize-detector';

import styles from './style.module.scss';
import Markdown from "../../../Markdown";
import Anchor from "../../../Anchor";

type QuestionAnswerProps = {
  data: {
    fragmentPath?: string,
    question: string,
    answer: any,
  },
  parentActive: boolean,
}

@observer
class QuestionAnswer extends React.Component<QuestionAnswerProps, any> {

  @observable active: boolean = false;

  @action
  setActive = (active: boolean): void => {
    this.active = active;
  };

  @observable height: number = 0;

  @action
  setHeight = (height: number): void => {
    this.height = height;
  };

  onClick = () => {
    this.setActive(!this.active);
  };

  handleHeightResize = (width: number, height: number) => {
    if (height !== 0) this.setHeight(height);
  };

  componentDidMount() {
    if (window) {
      if (location.hash && location.hash.replace('#', "") === this.props.data.fragmentPath) {
        this.setActive(true);
      }
    }

    reaction(
      () => this.props.parentActive,
      () => {
        this.setActive(this.props.parentActive);
      }
    );
  }

  render() {

    return (
      <div className={styles.wrapper}>
        {this.props.data.fragmentPath && <Anchor id={this.props.data.fragmentPath}/>}
        <div className={classNames({
          [styles.question]: true,
          [styles.active]: this.active
        })}
             onClick={this.onClick}>
          <span>{this.props.data.question}</span>
          <div className={classNames({
            [styles.arrow]: true,
            [styles.active]: this.active
          })}>
            <svg version="1.1"
                 x="0px"
                 y="0px"
                 viewBox="0 0 48 48">
              <path
                fill="#D9B282"
                d="M31.174,19.83c-0.419-0.451-1.096-0.451-1.514,0l-5.667,6.111l-5.666-6.111 c-0.418-0.451-1.096-0.451-1.514,0s-0.418,1.182,0,1.633l6.423,6.927c0.209,0.226,0.483,0.339,0.757,0.339 c0.274,0,0.548-0.113,0.757-0.339l6.424-6.927C31.592,21.012,31.592,20.281,31.174,19.83z"/>
            </svg>
          </div>
        </div>
        <div className={classNames({
          [styles.answer]: true,
          [styles.active]: this.active
        })} style={{
          height: this.active ? `${this.height}px` : 0,
          opacity: this.active ? 1 : 0
        }}>
          <div className={styles.markdown}>
            <ReactResizeDetector handleHeight onResize={this.handleHeightResize} />
            <Markdown markdown={this.props.data.answer}/>
          </div>
        </div>
      </div>
    )
  }
}

export default QuestionAnswer;