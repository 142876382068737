// @flow

import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';

import styles from './style.module.scss';
import QuestionAnswer from "./QuestionAnswer";
import * as classNames from "classnames";
import BlockHeader from "../BlockHeader";
import { wrapSymbols } from "../../../utils/wrap-symbol";

type QuestionAnswerBlockProps = {
  data: {
    header: any,
    title: string,
    questions: {
      fragmentPath?: string,
      question: string,
      answer: any,
    }[]
  },
}

@observer
class QuestionAnswerBlock extends React.Component<QuestionAnswerBlockProps, any> {

  @observable active: boolean = false;

  @action
  setActive = (): void => {
    this.active = !this.active;
  };

  render() {

    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          {this.props.data.header && <div className={styles.headerBlock}>
            <BlockHeader markdown={this.props.data.header} />
          </div>}
        </div>
        <div className={styles.title}>
          <span dangerouslySetInnerHTML={{ __html: wrapSymbols(this.props.data.title) }} />
          <div className={classNames({
            [styles.expand]: true,
            [styles.active]: this.active
          })}
            onClick={() => this.setActive()}>
            {this.active ? 'Collapse All' : 'Expand All'}
            <div className={classNames({
              [styles.arrow]: true,
            })}>
              <svg version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 48 48">
                <path
                  fill="#D9B282"
                  d="M31.174,19.83c-0.419-0.451-1.096-0.451-1.514,0l-5.667,6.111l-5.666-6.111 c-0.418-0.451-1.096-0.451-1.514,0s-0.418,1.182,0,1.633l6.423,6.927c0.209,0.226,0.483,0.339,0.757,0.339 c0.274,0,0.548-0.113,0.757-0.339l6.424-6.927C31.592,21.012,31.592,20.281,31.174,19.83z" />
              </svg>
            </div>
          </div>
        </div>
        {this.props.data.questions && this.props.data.questions.map((item, index) => {
          return (
            <QuestionAnswer key={`${index}QA`}
              data={item}
              parentActive={this.active} />
          )
        })}
      </div>
    )
  }
}

export default QuestionAnswerBlock;
