// @flow

import React from 'react';
import { observer } from 'mobx-react';

import styles from './style.module.scss';
type LineupBlockProps = {
  data: {
    widgetUrl: string
  }
}

@observer
class LineupBlock extends React.Component<LineupBlockProps, any> {

  ref: any;
  stores: any;

  constructor(props: LineupBlockProps, context: any) {
    super(props, context);
    this.ref = React.createRef();
  }

  componentDidMount() {
    if (window) {
      setTimeout(() => this.loadScript(), 10);
    }
  }

  loadScript() {
    if (this.ref.current) {
      const fragment: any = document.createRange().createContextualFragment(`
      <goevent-web id="goevent-web" project-tag="stagecoach-2020" project-hash="6361bf0d82664180888e5acc239858d4" data-language="eng"></goevent-web>`);
      let scriptTag: any = document.createElement('script');
      scriptTag.setAttribute('id', 'gw-script');
      scriptTag.setAttribute('data-gzip', 'true');
      scriptTag.src = `${this.props.data.widgetUrl}`;
      this.ref.current.appendChild(fragment);
      this.ref.current.appendChild(scriptTag);
    } else {
      setTimeout(() => {
        this.loadScript();
      }, 10);
    }
  }

  render() {

    return (
      <div className={styles.container}>
        <div className={styles.frameWrapper}
             ref={this.ref}>

        </div>
      </div>
    )
  }
}

export default LineupBlock;
