// @flow

import React from 'react'
import { observer } from 'mobx-react'
import * as classNames from 'classnames'

import styles from './style.module.scss'
import BlockHeader from '../BlockHeader'
import { Link } from 'gatsby'
import Markdown from '../../Markdown'
import getImageUrl from '../../../utils/get-image-url'

type PurchaseItemBlockProps = {
  data: {
    header?: any,
    disclaimer?: any,
    purchaseItems: {
      blurb?: any,
      price: string,
      priceColor?: string,
      priceUrl?: string,
      totUrl?: string,
      display: string,
      image: {
        file: {
          url: string,
        },
      },
      button: any,
    }[],
  },
}

@observer
class PurchaseItemBlock extends React.Component<PurchaseItemBlockProps, any> {
  render() {
    const LinkButton = props => {
      if (props.url) {
        return <Link to={props.url}>{props.children}</Link>
      } else
        return (
          <a href={props.external} target={'_blank'} rel="noopener noreferrer">
            {props.children}
          </a>
        )
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          {this.props.data.header && (
            <div className={styles.headerBlock}>
              <BlockHeader markdown={this.props.data.header} />
            </div>
          )}
        </div>
        <div
          className={classNames({
            [styles.itemsWrapper]: true,
            [styles.wide]: this.props.data.purchaseItems.length > 5,
            [styles.sixItem]: this.props.data.purchaseItems.length === 5,
            [styles.fourItem]: this.props.data.purchaseItems.length === 4,
          })}
        >
          {this.props.data.purchaseItems &&
            this.props.data.purchaseItems.map((item, index) => {
              return (
                <>
                  <div
                    key={`${index}purchaseItemBlock`}
                    className={classNames({
                      [styles.container]: true,
                      [styles.blurb]: !!item.blurb,
                      [styles.wideItem]:
                        this.props.data.purchaseItems.length > 6,
                      [styles.sixItem]:
                        this.props.data.purchaseItems.length === 5,
                      [styles.fourItem]:
                        this.props.data.purchaseItems.length === 4,
                    })}
                  >
                    <div className={styles.ratio}>
                      <div className={styles.absolute}>
                        <div className={styles.imageWrapper}>
                          <img src={getImageUrl(item.image)} />
                        </div>
                        <h3
                          dangerouslySetInnerHTML={{
                            __html: item.display,
                          }}
                        ></h3>
                        <h4
                          className={classNames({
                            [styles.soldOut]: item.priceColor === 'blue',
                          })}
                        >
                          {item.priceUrl && item.totUrl && (
                            <>
                              {`${item.price} + `}
                              <LinkButton external={item.totUrl || ''}>
                                {`TOT`}
                              </LinkButton>
                              {` + `}
                              <LinkButton external={item.priceUrl || ''}>
                                {`fees`}
                              </LinkButton>
                            </>
                          )}
                          {item.priceUrl && !item.totUrl && (
                            <>
                              {`${item.price} + `}
                              <LinkButton external={item.priceUrl || ''}>
                                {`fees`}
                              </LinkButton>
                            </>
                          )}
                          {!item.priceUrl && (
                            <LinkButton url={`/fan-to-fan-exchange#content`}>
                              {item.price}
                            </LinkButton>
                          )}
                        </h4>
                        <div className={styles.button}>
                          <LinkButton
                            url={item.button.url || ''}
                            external={item.button.externalUrl || ''}
                          >
                            {item.button.display}
                          </LinkButton>
                        </div>
                      </div>
                    </div>
                    <div className={styles.markdown}>
                      {item.blurb && <Markdown markdown={item.blurb} />}
                    </div>
                  </div>
                </>
              )
            })}
        </div>
        <div className={styles.disclaimer}>
          {this.props.data.disclaimer && (
            <div className={styles.disclaimerText}>
              <Markdown markdown={this.props.data.disclaimer} />
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default PurchaseItemBlock
