// @flow

import React from 'react';
import { observer } from 'mobx-react';
// import includes from 'lodash/includes';

import styles from './style.module.scss';
import { Link } from "gatsby";

type NavigationBlockProps = {
  data: {
    fragmentLinks: boolean,
    links: {
      display: string,
      url: string,
      externalUrl: string,
    }[]
  },
}

@observer
class NavigationBlock extends React.Component<NavigationBlockProps, any> {

  render() {

    const isActive = ({ href, location }) => {
      if (this.props.data.fragmentLinks) return null;
      // const isCurrent = includes(href, location.pathname);
      let isCurrent = href === location.pathname;
      if (location.hash) isCurrent = href === `${location.pathname}${location.hash}`;
      return isCurrent ? { className: styles.active } : null
    };

    const ExactNavLink = props => {
      return (
        <Link getProps={isActive} {...props} />
      )
    };

    return (
      <div className={styles.wrapper}>
        <div className={styles.bg}>

        </div>
        {this.props.data.links && this.props.data.links.map((item, index) => {
          return item.url ? (
            <ExactNavLink
              key={`${index}NavBlock`}
              to={item.url}>
              {item.display}
            </ExactNavLink>
          ) : (
              <a
                href={item.externalUrl}
                target={'_blank'}
                rel="noopener noreferrer"
              >
                {item.display}
              </a>
            )
        })}
      </div>
    )
  }
}

export default NavigationBlock;
