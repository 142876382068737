// @flow

import React from 'react';
import { observer } from 'mobx-react';
import * as classNames from "classnames";

import styles from './style.module.scss';
import getImageUrl from "../../../utils/get-image-url";
import Anchor from "../../Anchor";
import BlockHeader from "../BlockHeader";
import Divider from "../../Divider";
import { Link } from "gatsby";

type LogoBlockProps = {
  data: {
    type?: 'white' | 'beige-blue' | 'round',
    dividerBelow: boolean,
    header?: any,
    fragmentPath?: string,
    logos: {
      display?: string,
      url?: string,
      externalUrl?: string,
      image: any,
    }[]
  },
}

@observer
class LogoBlock extends React.Component<LogoBlockProps, any> {

  render() {

    const LinkButton = props => {
      if (props.url) {
        return (
          <Link to={props.url}>
            {props.children}
          </Link>
        )
      } else return (
        <a href={props.external}
          target={'_blank'} rel="noopener noreferrer">
          {props.children}
        </a>
      )
    };

    return (
      <div className={styles.wrapper}>
        {this.props.data.fragmentPath && <Anchor id={this.props.data.fragmentPath} />}
        <div className={styles.header}>
          {this.props.data.header && <div className={styles.headerBlock}>
            <BlockHeader markdown={this.props.data.header} />
          </div>}
        </div>
        <div className={classNames({
          [styles.logos]: true,
          [styles.beige]: this.props.data.type === 'beige-blue',
          [styles.round]: this.props.data.type === 'round'
        })}>
          {this.props.data.logos.map((item, index) => {
            return (
              <div key={`${index}logoBlock`}
                className={styles.item}>
                <div className={styles.ratio}>
                  <LinkButton
                    url={item.url || ''}
                    external={item.externalUrl || ''}>
                    <img src={getImageUrl(item.image)} />
                  </LinkButton>
                </div>
                {this.props.data.type !== 'beige-blue' && <h3>{item.display}</h3>}
                {this.props.data.type === 'beige-blue' && <LinkButton
                  url={item.url || ''}
                  external={item.externalUrl || ''}>
                  <h3>{item.display}</h3>
                </LinkButton>}
              </div>
            )
          })}
          {this.props.data.dividerBelow && <div className={styles.divider}>
            <Divider color={'#ff6d64'} />
          </div>}
        </div>
      </div>
    )
  }
}

export default LogoBlock;
